import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    title: "RTX Legends <br /> of Summer",
    description_header_1: "Join us with GeForce RTX Legends of Summer and join your favorite influencer's team to help them rise to the top.",
    description_header_2: "Community members pick a team and participate in challenges to earn points for their chosen influencer. Influencers have their own set of challenges to complete, adding points to their total. The more points earned, the higher they climb on the leaderboard. Get RTX On and gear up for this legendary competition.",
    pick_your_creator: "Pick your Creator",
    dlls_text: 'Experience <i>Black Myth: Wukong</i> With <br> GeForce RTX 40 Series',
    ray_tracing_1: 'Full Ray Tracing',
    ray_tracing_2: 'An Epic Fully Ray-Traced World Awaits',
    ray_tracing_3: 'Experience <i>Black Myth: Wukong</i> with full ray tracing, also known as path tracing, for the most immersive experience powered by GeForce RTX 40 Series. Full ray tracing realistically simulates properties of light from an unlimited number of emissive sources, delivering physically correct shadows, reflections and global illumination on all objects.',
    specs_title: "Upgrade for the Ultimate Journey",
    specs_description: "Play <i>Black Myth: Wukong</i> with full ray tracing and NVIDIA DLSS on GeForce RTX 40 Series. <br> Max FPS. Max Quality. Powered by AI.",
    buy_geforce: "Buy GeForce RTX",
    learn_more: "Learn More",
    challenges: "Challenges",
    creators_challenges: "Creators Challenges",
    community_challenges: "Community Challenges",
    pts: "Pts",
    leaderboard: "Leaderboard",
    influencer_name: "Influencer Name",
    influencer_pts: "Influencer Pts",
    community_pts: "Community Pts",
    total_pts: "Total Pts",
    footer_text_1: "Copyright © Game Science Interactive Technology Co., Ltd. All Rights Reserved 游科互动科技有限公司",
    footer_text_2: "© 2024 NVIDIA Corporation. All rights reserved. NVIDIA, the NVIDIA logo, and GeForce RTX are trademarks and/or registered trademarks of NVIDIA Corporation in the U.S. and other countries. Other company and product names may be trademarks of the respective companies with which they are associated. "

  },
  ar: {
    title: "أساطير <br /> صيف RTX",
    description_header_1: "انضموا إلينا في تحدي أساطير صيف GeForce RTX وشاركوا مع فريق المؤثر المفضل لديكم لمساعدته في الوصول إلى القمة.",
    description_header_2: "يختار أعضاء المجتمع فريقًا ويشاركون في تحديات لكسب النقاط لفريق المؤثر الذي اختاروه. المؤثرون لديهم مجموعة خاصة بهم من التحديات التي يجب عليهم إكمالها لإضافة النقاط إلى مجموعهم. كلما زادت النقاط التي يتم كسبها، كلما ارتفعوا في لوحة الترتيب. قوموا بتشغيل خاصية RTX واستعدوا لهذه المنافسة الأسطورية.",
    pick_your_creator: "اختر المؤثر المفضل لديك",
    dlls_text: ' استمتع بتجربة <i> Black Myth: Wukong </i> مع <br> سلسلة GeForce RTX 40',
    ray_tracing_1: 'تقنية تعقب الشعاع بالكامل',
    ray_tracing_2: 'عالم ملحمي بتقنية تعقب الشعاع بالكامل في انتظارك ',
    ray_tracing_3: 'استمتع بتجربة لعبة Black Myth: Wukong للحصول علىجربة غامرة لا مثيل لها بتقنية تعقب الشعاع بالكامل، المعروفة أيضًا باسم تتبُّع المسار المدعومة بسلسلة بطاقات رسومات GeForce RTX 40. تحاكي تقنية تعقب الشعاع بالكامل بصورة أقرب إلى الواقع خصائص الضوء المنبعث من عدد غير محدود من مصادر الانبعاثات، مما يوفر ظِلالًا وانعكاسات وإضاءة شاملة دقيقة فيزيائيًا على جميع عناصر اللعبة.',
    specs_title: "ارتقِ للوصول إلى الرحلة النهائية",
    specs_description: "العب  <i>Black Myth: Wukong</i> مع تقنية تعقب الشعاع بالكامل و NVIDIA DLSS على سلسلة بطاقة رسومات GeForce RTX 40. <br> أقصى معدل إطارات في الثانية. أفضل جودة. مدعومة بالذكاء الاصطناعي. ",
    buy_geforce: "اشترِ بطاقة رسومات GEFORCE RTX",
    learn_more: "تعرف على المزيد",
    challenges: "التحديات",
    creators_challenges: "تحديات المؤثر",
    community_challenges: "تحديات المجتمع",
    pts: "النقاط",
    leaderboard: "Leaderboard",
    influencer_name: "اسم المؤثر",
    influencer_pts: "نقاط المؤثر",
    community_pts: "نقاط المجتمع",
    total_pts: "مجموع النقاط",
    footer_text_1: "حقوق الطبع والنشر© محفوظة لشركة Game Science Interactive Technology Co., Ltd. جميع الحقوق محفوظة 游科互动科技有限公司",
    footer_text_2: "حقوق الطبع والنشر© محفوظة لشركة NVIDIA Corporation لعام 2024. جميع الحقوق محفوظة. NVIDIA وشعار NVIDIA وGeForce RTX هي علامات تجارية مسجلة، و/أو علامات تجارية لصالح شركة NVIDIA Corporation في الولايات المتحدة وغيرها من البلدان. قد تكون أسماء الشركات والمنتجات الأخرى علامات تجارية للشركات المعنية التي ترتبط بها. "
  }
};

const savedLanguage = localStorage.getItem('language') || 'ar';

const i18n = createI18n({
  legacy: false,
  locale: savedLanguage,
  fallbackLocale: 'ar',
  messages,
});

export default i18n;
