<template>
  <div class="fullscreen-background">
    <div class="titulos-container">
      <div class="tlt-main">{{ $t("specs_title") }}</div>
      <div class="titulo-page" v-html="$t('specs_description')"></div>
      <img :src="imageSource" alt="Specs Image" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const imageSource = computed(() => {
  return locale.value === 'ar'
    ? 'https://cdn.egamena.com/rtx-legends/specs_ar.png'
    : 'https://cdn.egamena.com/rtx-legends/specs_en.png';
});
</script>



<style scoped>
.fullscreen-background {
  background-image: url("https://cdn.egamena.com/rtx-legends/specs_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: 80px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
}

.tlt-main {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
}

.titulos-container {
  display: flex;
  max-width: 1130px;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}

.titulo-page {
  color: white;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 40px;
}

@media (max-width: 580px) {
  .fullscreen-background {
    padding: 30px;
  }
}
</style>
