<template>
  <div class="fullscreen-background">
    <div class="titulo-page" v-html="$t('dlls_text')"></div>

    <div class="video-container">
      <iframe
        src="https://iframe.mediadelivery.net/embed/310900/35df8bb0-faeb-4561-b261-451f5ca65dd2?autoplay=true&loop=false&muted=true&preload=true&responsive=true"
        loading="lazy"
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        allowfullscreen="true">
      </iframe>
    </div>
  </div>
</template>

<style scoped>
.fullscreen-background {
  background-image: url('https://cdn.egamena.com/rtx-legends/video_section_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.titulo-page {
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 40px;
}

.video-container {
  width: 100%;
  max-width: 620px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
@media (max-width: 580px) {
  .fullscreen-background {
    padding: 30px;
  }
}
</style>
