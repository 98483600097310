<template>
  <main>
    <div class="leaderboard">
      <h2>Leaderboard</h2>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("influencer_name") }}</th>
            <th>{{ $t("influencer_pts") }}</th>
            <th>{{ $t("community_pts") }}</th>
            <th>{{ $t("total_pts") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in leaderboardEntries" :key="index">
            <td>{{ index + 1 }}</td> <!-- Ranking basado en el índice -->
            <td>{{ entry.name }}</td>
            <td>{{ entry.influncerPoints }}</td>
            <td>{{ entry.communityPoints }}</td>
            <td>{{ entry.totalPoints }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const leaderboardEntries = ref([]);

const fetchLeaderboard = async () => {
  try {
    const response = await fetch('https://api.egamena.com/v1/rtx-legends/leaderboard');
    const data = await response.json();
    leaderboardEntries.value = data;
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
  }
};

onMounted(() => {
  fetchLeaderboard();
});
</script>

<style scoped>
main {
  background-color: #1c1c1c;
  padding: 0px 80px 80px 80px;
}

.leaderboard {
  color: white;
  margin: auto;
  max-width: 1150px;
  width: 100%;
}

h2 {
  text-align: center;
  margin-top: 0;
  font-size: 2rem;
  margin-bottom: 40px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

th {
  font-weight: bold;
}

th,
td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #6e6e6e;
}

tr {
  margin-bottom: 20px;
}

td {
  font-size: 20px;
  font-weight: bold;
  background-color: #000000;
}

@media (max-width: 768px) {
  main {
    background-color: #1c1c1c;
    padding: 0px 30px 30px 30px;
  }

  th:nth-child(3),
  th:nth-child(4),
  td:nth-child(3),
  td:nth-child(4) {
    display: none;
  }
}
</style>
