<template>
  <main>
    <div class="left-side">
      <div class="data-left">
        <div class="top">
          <div class="main-tlt-page" v-html="$t('title')"></div>
          <div class="description-conts">
            <div class="description-main-page">
              {{ $t("description_header_1") }}
            </div>
            <div class="description-main-page">
              {{ $t("description_header_2") }}
            </div>
          </div>
        </div>
        <div class="btn-logo-nvidia">
          <img
            src="https://cdn.egamena.com/rtx-legends/rtx_ai_logo.png"
            alt="NVIDIA AI Logo"
            class="logo-nvidia-ai"
          />
        </div>
      </div>
    </div>
    <div class="right-side">
      <img
        :src="mainImageSrc"
        class="header_img_main"
        :alt="mainImageAlt"
      />
    </div>
  </main>
</template>

<script>
export default {
  computed: {
    // Computed property to determine the image source based on language
    mainImageSrc() {
      return this.$i18n.locale === "ar"
        ? "https://cdn.egamena.com/rtx-legends/main_home_img_ar.png"
        : "https://cdn.egamena.com/rtx-legends/main_home_img.png";
    },
    mainImageAlt() {
      return this.$i18n.locale === "ar"
        ? "صورة أساطير RTX الرئيسية"
        : "RTX Legends Main Image";
    },
  },
};
</script>

<style scoped>
main {
  background-color: #000;
  display: flex;
  color: #fff;
  min-height: 100vh;
  flex-wrap: wrap;
}

.left-side {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 50%;
  flex-direction: column;
}

.right-side {
  display: flex;
  width: 50%;
}

.data-left {
  max-width: 90%;
  padding: 50px 100px;
  margin: auto;
  justify-content: space-between;
  max-height: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.description-conts {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.btn-logo-nvidia {
  height: auto;
  max-height: 200px;
}

.description-main-page {
  font-size: 20px;
  font-weight: 300;
}

.logo-nvidia-ai {
  height: 70px;
  width: auto;
  display: flex;
}

.header_img_main {
  width: 100%;
  height: auto;
  display: flex;
}

.main-tlt-page {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 40px;
}

@media (max-width: 1024px) {
  .left-side,
  .right-side {
    width: 100%;
  }

  .data-left {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 100%;
    padding-bottom: 50px;
  }

  .main-tlt-page {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .description-main-page {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .main-tlt-page {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .description-main-page {
    font-size: 16px;
  }

  .logo-nvidia-ai {
    height: 50px;
  }
}
@media (max-width: 480px) {
  .data-left {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
  }

  .main-tlt-page {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .description-main-page {
    font-size: 14px;
  }

  .logo-nvidia-ai {
    height: 40px;
  }
}
</style>
