<template>
  <main>
    <div class="main-tlt-page">{{ $t("pick_your_creator") }}</div>
    <div class="creators-container">
      <a href="https://api.egamena.com/v1/discord/auth/1133318434516631582/1289626918177476648" target="_blank" rel="noopener noreferrer">
        <div class="creator-container">
          <img
            src="https://cdn.egamena.com/rtx-legends/shong_img.png"
            alt="SHoNgxBoNg"
            class="creator-img"
          />
          <div class="creator-name">SHoNgxBoNg</div>
        </div>
      </a>
      <a href="https://api.egamena.com/v1/discord/auth/1133318434516631582/1289627834624643123" target="_blank" rel="noopener noreferrer">
        <div class="creator-container">
          <img
            src="https://cdn.egamena.com/rtx-legends/rakan_img.png"
            alt="Power Rakan"
            class="creator-img"
          />
          <div class="creator-name">Power Rakan</div>
        </div>
      </a>
    </div>
  </main>
</template>


<style scoped>
main {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 50px;
}

.main-tlt-page {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.creators-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
}

.creator-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}

.creator-name {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
}

.creator-img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  transition: all 0.3s;
  transform: scale(1.03);
}

@media (max-width: 768px) {
  main {
    padding: 30px;
    gap: 40px;
  }

  .main-tlt-page {
    font-size: 24px;
  }

  .creators-container {
    gap: 40px;
  }

  .creator-name {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  main {
    padding: 20px;
    gap: 30px;
  }

  .main-tlt-page {
    font-size: 20px;
  }

  .creators-container {
    gap: 30px;
  }

  .creator-container {
    gap: 16px;
  }

  .creator-name {
    font-size: 18px;
  }
}
</style>