<template>
  <main>
    <div class="challenges-title">{{ $t("challenges") }}</div>
    <div class="challenges">
      <div class="challenges-container">
        <div class="challenge-column">
          <div class="header-top">
            <div class="main-tlt">{{ $t("creators_challenges") }}</div>
            <div class="pts-tlt">{{ $t("pts") }}</div>
          </div>
          <ul>
            <li v-for="(challenge, index) in translatedCreatorChallenges" :key="index">
              <span class="challenge-description">{{ challenge.description }}</span>
              <span class="challenge-points">{{ challenge.points }}</span>
            </li>
          </ul>
        </div>
        <div class="challenge-column">
          <div class="header-top">
            <div class="main-tlt">{{ $t("community_challenges") }}</div>
            <div class="pts-tlt">{{ $t("pts") }}</div>
          </div>
          <ul>
            <li v-for="(challenge, index) in translatedCommunityChallenges" :key="index">
              <span class="challenge-description">{{ challenge.description }}</span>
              <span class="challenge-points">{{ challenge.points }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const creatorChallenges = [
  {
    description: {
      en: "Stream for an extra hour",
      ar: "بث لمدة ساعة إضافية",
    },
    points: 50,
  },
  {
    description: {
      en: "Talk about NVIDIA technologies available in the game",
      ar: "تكلم عن تقنيات إنفيديا الموجودة في اللعبة ",
    },
    points: 30,
  },
  {
    description: {
      en: "Inviting a guest and let him talk and explain the NVIDIA technologies",
      ar: "قم بدعوة ضيف للتكلم ومناقشة تقنيات إنفيديا ",
    },
    points: 60,
  },
  {
    description: {
      en: "Showcase how to turn DLSS in game settings",
      ar: "قم بتوضيح كيفية تفعيل تقنية التعلّم العميق DLSS من إعدادات اللعبة ",
    },
    points: 50,
  },
  {
    description: {
      en: "Drive viewers to NVIDIA MENA TikTok to follow & talk to them about the benefits of following us",
      ar: "تحدث عن مزايا متابعة حساب إنفيديا على تيك توك ",
    },
    points: 40,
  },
  {
    description: {
      en: "Show the microsite and talk about how the community should participate",
      ar: "إشرح كيفية مشاركة المجتمع في التحديات لزيادة فرصك في الفوز",
    },
    points: 20,
  },
  {
    description: {
      en: "Tweet about DLSS and let the community participate",
      ar: "قم بالتغريد عن DLSS وافتح المجال لمشاركة مجتمعك ",
    },
    points: 15,
  },
];

const communityChallenges = [
  {
    description: {
      en: "Turn on DLSS, take a screenshot, and share it in your favorite creator's Discord channel",
      ar: "قم بتفعيل تقنية التعلّم العميق وإلتقاط صورة لها وهي مفعلة",
    },
    points: 25,
  },
  {
    description: {
      en: "Take an artistic RTX ON screenshot of the game, and share it in your favorite creators' Discord channel",
      ar: "قم بإلتقاط صورة فنية والتقنيات مفعلّة، أرنا إبداعك مع RTXOn وقم بمشاركتها في قناة صانع المحتوى المفضل لديك",
    },
    points: 50,
  },
  {
    description: {
      en: "Retweet your favorite creators' DLSS tweet, and share it in your favorite creators' Discord channel",
      ar: "قم بإعادة نشر منشور تقنية التعلّم العميق من صانع المحتوى المفضل لديك",
    },
    points: 10,
  },
  {
    description: {
      en: "Take screenshot of your preferred RTX product from the website and share it on your favourite creators' Discord channel",
      ar: "قم بإلتقاط صورة لمنتج RTX المفضل لديك ومشاركتها في قناة صانع المحتوى المفضل لديك على الديسكورد",
    },
    points: 10,
  },
  {
    description: {
      en: "Take a screenshot of the NVIDIA app in your desktop and share it in your favorite creators' Discord channel",
      ar: "قم بتشغيل وإلتقاط صورة لتطبيق إنفيديا او GeForce Experience وشاركها في قناة صانع المحتوى المفضل لديك على الديسكورد",
    },
    points: 30,
  },
  {
    description: {
      en: "Follow NvidiaME on TikTok, engage and interact with all posts",
      ar: "قم بمتابعة حساب @NVIDIAGeForceME على تيكتوك، والتفاعل مع محتوى القناة",
    },
    points: 60,
  },
];

const translatedCreatorChallenges = computed(() =>
  creatorChallenges.map((challenge) => ({
    description: challenge.description[locale.value],
    points: challenge.points,
  }))
);

const translatedCommunityChallenges = computed(() =>
  communityChallenges.map((challenge) => ({
    description: challenge.description[locale.value],
    points: challenge.points,
  }))
);
</script>

<style scoped>
.challenges-title {
  color: #fff;
  text-align: center;
  padding: 0px 40px 40px 40px;
  font-size: 2rem;
  font-weight: bold;
}

main {
  background-color: #1c1c1c;
  padding: 40px 80px 80px 80px;
  max-width: 100%; /* Evita que se salga del contenedor */
  box-sizing: border-box; /* Asegura que el padding se incluya en el ancho total */
}

.challenges {
  background-color: #101010;
  color: white;
  max-width: 1150px;
  width: 100%;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
}

.challenges-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Añade espacio entre columnas para que no se junten en pantallas pequeñas */
}

.challenge-column {
  width: 100%; /* Por defecto toma todo el ancho */
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  display: grid;
  gap: 12px;
  font-weight: 400;
}

li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Para que el contenido se ajuste si es demasiado largo */
  margin-bottom: 10px;
}

.challenge-description {
  flex: 1;
}

.challenge-points {
  font-weight: 400;
  margin-left: 10px;
}

.header-top {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 22px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .challenge-column {
    width: 48%; /* Columnas lado a lado en pantallas más grandes */
  }
}

@media (max-width: 768px) {
  .challenges-title {
    font-size: 1.75rem;
    padding: 0px 20px 30px 20px;
  }

  main {
    padding: 20px 20px 40px 20px;
  }

  .challenges {
    padding: 20px;
  }

  .challenge-points {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .challenges-title {
    font-size: 1.5rem;
  }

  main {
    padding: 15px 15px 30px 15px;
  }

  .challenges {
    padding: 15px;
  }

  .header-top {
    font-size: 16px;
  }
}
</style>