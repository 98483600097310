<template>
  <div class="fullscreen-background">
  <div class="titulos-container">
    <div class="tlt-small-main">{{ $t("ray_tracing_1") }}</div>
        <div class="tlt-main">{{ $t("ray_tracing_2") }}</div>
    <div class="titulo-page" v-html="$t('ray_tracing_3')"></div>

  </div>

  </div>
</template>

<style scoped>
.fullscreen-background {
  background-image: url("https://cdn.egamena.com/rtx-legends/full-ray-trace-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: 80px;
  height: 80vh;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
}
.tlt-small-main {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.tlt-main {
    color: #fff;
  font-size: 32px;
  font-weight: bold;

}
.titulos-container {
  display: flex;
  max-width: 1130px;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}
.titulo-page {
  color: white;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 40px;
}
@media (max-width: 580px) {
  .fullscreen-background {
    padding: 30px;
  }
}

</style>
