<template>
  <div id="app">
    <HeaderComp />
    <PickYourCreator />
    <DLLSection />
    <RayTracing />
    <SpecsComp />
    <ProductList />
    <ChallengesList />
    <LeaderboardList />
    <FooterComp />
    <LanguageSelector />
  </div>
</template>

<script>
import HeaderComp from './components/HeaderComp.vue';
import PickYourCreator from './components/PickYourCreator.vue';
import DLLSection from './components/DLLSection.vue';
import SpecsComp from './components/SpecsComp.vue';
import RayTracing from './components/RayTracing.vue';
import ProductList from './components/ProductList.vue';
import ChallengesList from './components/ChallengesList.vue';
import LeaderboardList from './components/LeaderboardList.vue';
import FooterComp from './components/FooterComp.vue';
import LanguageSelector from './components/LanguageSelector.vue';

export default {
  components: {
    HeaderComp,
    LanguageSelector,
    PickYourCreator,
    DLLSection,
    SpecsComp,
    RayTracing,
    ProductList,
    ChallengesList,
    FooterComp,
    LeaderboardList,
  }
};
</script>

<style>
body.rtl {
  margin: 0;
  direction: rtl;
}
body {
  margin: 0;
  direction: ltr;
}
</style>
