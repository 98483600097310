<template>
  <main>
    <div class="geforce-rtx-grid">
      <h1>{{ $t("buy_geforce") }}</h1>
      <div class="product-grid">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="product-card"
        >
          <div class="product-image">
            <img :src="product.img" alt="" class="img-product" />
          </div>
          <div class="main-name-product">
            <h2>{{ product.type }}</h2>
            <h2>{{ product.name }}</h2>
          </div>
          <a
            :href="product.link"
            target="_blank"
            rel="noopener noreferrer"
            class="learn-more"
            >{{ $t("learn_more") }}</a
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
const products = [
  {
    type: "ZOTAC TWIN EDGE",
    name: "RTX 4060",
    link: "https://www.infiniarc.com/en/shop/zt-d40600q-10m-zotac-gaming-geforce-rtx-4060-8gb-gddr6-twin-edge-oc-white-edition-gaming-graphics-card-4271",
    img: "https://cdn.egamena.com/rtx-legends/products/1.png",
  },
  {
    type: "GALAX 2X V2",
    name: "RTX 4060",
    link: "https://pcd.com.sa/en/product/%d8%a8%d8%b7%d8%a7%d9%82%d8%a9-%d8%b9%d8%b1%d8%b6-%d9%85%d8%b1%d8%a6%d9%8a-%d8%ac%d8%a7%d9%84%d8%a7%d9%83%d8%b3-%d8%ac%d9%8a%d9%81%d9%88%d8%b1%d8%b3-rtx4060-%d8%a8%d8%b0%d8%a7%d9%83%d8%b1%d8%a9-ram-gd/",
    img: "https://cdn.egamena.com/rtx-legends/products/2.png",
  },
  {
    type: "ASUS DUAL EVO",
    name: "RTX 4060",
    link: "https://pcd.com.sa/en/product/asus-dual-geforce-rtx-4060-evo-oc-edition-8gb-gddr6-pcie-4-0-8gb-gddr6-dlss-3-0db-technology-dual-bios-dual-rtx4060-o8g-evo/",
    img: "https://cdn.egamena.com/rtx-legends/products/3.png",
  },
  {
    type: "ASUS TUF",
    name: "RTX 4070",
    link: "https://www.infiniarc.com/en/shop/90yv0k80-m0na00-asus-tuf-gaming-geforce-rtx-4070-super-12gb-gddr6x-oc-edition-6262",
    img: "https://cdn.egamena.com/rtx-legends/products/4.png",
  },
  {
    type: "MSI VENTUS 3X",
    name: "RTX 4070 TI",
    link: "https://pcd.com.sa/en/product/msi-rtx-4070ti-super-16g-ventus-3x-oc/",
    img: "https://cdn.egamena.com/rtx-legends/products/5.png",
  },
  {
    type: "ZOTAC SUPER TWIN",
    name: "RTX 4070",
    link: "https://www.infiniarc.com/en/shop/zt-d40720q-10m-zotac-gaming-geforce-rtx-4070-super-twin-edge-oc-12gb-gddr6x-white-edition-6264",
    img: "https://cdn.egamena.com/rtx-legends/products/6.png",
  },
  {
    type: "ASUS ROG STRIX",
    name: "RTX 4090",
    link: "https://www.infiniarc.com/en/shop/90yv0id0-m0na00-asus-rog-strix-geforce-rtx-4090-oc-edition-24gb-gddr6x-3721",
    img: "https://cdn.egamena.com/rtx-legends/products/7.png",
  },
  {
    type: "GIGABYTE",
    name: "RTX 4090",
    link: "https://www.infiniarc.com/en/shop/gv-n4090gaming-oc-24gd-gigabyte-geforce-rtx-4090-gaming-oc-24g-3130",
    img: "https://cdn.egamena.com/rtx-legends/products/8.png",
  },
  {
    type: "GALAX HOF",
    name: "RTX 4090",
    link: "https://pcd.com.sa/en/product/galax-rtx-4090-hof-24gb-gddr6x-white/",
    img: "https://cdn.egamena.com/rtx-legends/products/9.png",
  },
];
</script>

<style scoped>
main {
  background-color: black;
}
.geforce-rtx-grid {
  background-color: black;
  color: white;
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}
.img-product {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 30px;
}

.product-image {
  background-color: #999999;
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-icon {
  width: 4rem;
  height: 4rem;
  color: #ff00ff;
}

h2 {
  margin: 0;
  font-size: 22px;
}

.learn-more {
  background-color: #76b900;
  font-weight: bold;
  color: black;
  border: none;
  text-decoration: none;
  font-size: 18px;
  padding: 0.7rem 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.learn-more:hover {
  background-color: #84cf02;
}
.main-name-product {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
}
@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
}
</style>