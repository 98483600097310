<template>
  <main>
    <footer>
        <div class="img-footer-main">
            <img src="https://cdn.egamena.com/rtx-legends/footer-logo.png" alt="" class="brands-footer">
        </div>
        <div class="text-footer-cont">
            <div class="text1">{{ $t('footer_text_1') }}</div>
            <div class="text2">{{ $t('footer_text_2') }}</div>
        </div>
    </footer>
  </main>
</template>


<style scoped>

main {
    background-color: #000;
    padding: 0 20px;
}
footer {
    max-width: 1200px;
    padding: 50px 0;
    width: 100%;
    margin: auto;
}
.img-footer-main {
    border-top: 1px solid #999999bb;
    justify-content: center;
    display: flex;
    padding: 50px 0;
    border-bottom: 1px solid #999999bb;
    width: 100%;
}
.text-footer-cont {
    display: flex;
    padding-top: 50px;
    color: #999999;
    text-align: center;
    gap: 20px;
    font-weight: 100;
    flex-direction: column;
}
</style>