<template>
  <div class="language-selector">
    <select v-model="currentLanguage" @change="changeLanguage">
      <option value="en">EN</option>
      <option value="ar">AR</option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: localStorage.getItem("language") || "ar", // Árabe como idioma predeterminado
    };
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.currentLanguage;
      document.body.className = this.currentLanguage === "ar" ? "rtl" : "";
      localStorage.setItem("language", this.currentLanguage);
    },
  },
  mounted() {
    if (this.currentLanguage === "ar") {
      document.body.className = "rtl";
    }
  },
};
</script>

<style>
.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
}

.language-selector select {
  background-color: #76b900;
  color: white;
  border: none;
  width: 40px;
  height: 30px;
  font-size: 12px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  text-align-last: center;
}

.language-selector select::-ms-expand {
  display: none;
}

.language-selector select:focus {
  outline: none;
  cursor: pointer;
  box-shadow: 0 0 5px #75b900c9;
}

body.rtl {
  direction: rtl;
}
</style>
